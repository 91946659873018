import styled from 'styled-components';

export const PageGrid = styled.div`
  margin: 40px 0;

  @media (min-width: 801px) {
    display: flex;
  }

  h1 {
    font-weight: bold;

    &:after {
      content: '';
      display: block;
      margin: 10px 0 30px;
      width: 120px;
      height: 5px;
      background: var(--dgreen);
    }
  }
`;
