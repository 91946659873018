import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function SEO({ children, location, description, title, image }) {
  const { site, sanitySiteSettings } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
      sanitySiteSettings {
        logo {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);
  const getSchemaMarkup = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'Emerald Heating',
    image: `${sanitySiteSettings.logo?.asset?.fluid?.src}`,
    logo: `${sanitySiteSettings.logo?.asset?.fluid?.src}`,
    telephone: '07538008378',
    email: 'emeraldheating20@gmail.com',
    url: 'https://emerald-heating.com/',
    priceRange: '$$',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Oxford Close',
      addressLocality: 'Elmswell',
      postalCode: 'IP30 9UE',
      addressCountry: 'GB',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 52.2346935,
      longitude: 0.9165194,
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '07:00',
        closes: '20:00',
      },
    ],
  });

  return (
    <Helmet
      titleTemplate={`%s | Plumbing & Heating Enginners in Suffolk | ${site.siteMetadata.title}`}
    >
      <html lang="en" />
      <title>{title}</title>
      {/* Fav Icons */}
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="alternate icon" href="/favicon.ico" />
      {/* Meta Tags */}
      <meta charset="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      {/* Open Graph */}
      {location && <meta property="og@url" content={location.href} />}
      <meta
        property="og:image"
        content={image || sanitySiteSettings.logo?.asset?.fluid?.src}
      />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta
        property="og:description"
        content={description}
        key="ogdescription"
      />
      <script type="application/ld+json">{getSchemaMarkup}</script>
    </Helmet>
  );
}
